import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const ContactPage = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "photo-1551434678-e076c223a692.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 1500
              quality: 70
              webpOptions: { quality: 100 }
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  return (
    <Layout>
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
                  <span className="block xl:inline">Επικοινωνήστε με την</span>{" "}
                  <span className="block text-indigo-600 xl:inline">
                    Virtual Sun
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Error, quaerat, ullam sed blanditiis qui asperiores fuga quas
                  ipsam illo, neque vitae quidem unde numquam est nesciunt
                  soluta autem natus quae!
                </p>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <GatsbyImage
            image={image}
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          />
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
